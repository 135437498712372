<template>
  <div class="supplier bj">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>
      <div class="seach">
        <div class="inputs">
          <el-input
            v-model="from1.supplier_title"
            placeholder="请输入供应商"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            filterable
            v-model="from1.logistics_id"
            clearable
            placeholder="请选择城市仓"
            :loading="loadingCityStore"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id.toString()"
            >
            </el-option>
          </el-select>
        </div>

        <div class="inputs">
          <el-input
            v-model="from1.order_no"
            placeholder="请输入订单号"
            clearable
          ></el-input>
        </div>
        <div style="margin-right: 10px">
          <el-date-picker
            v-model="time"
            :time-arrow-control="true"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="—"
            start-placeholder="完成日期起"
            end-placeholder="完成日期止"
            @change="startchanghe"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.mobile"
            placeholder="请输入客户电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.as_no"
            placeholder="请输入售后单号"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.after_type"
            clearable
            placeholder="请选择售后类型"
          >
            <el-option label="用户售后" value="1"></el-option>
            <el-option label="品控售后" value="2"></el-option>
          </el-select>
        </div>
        <el-button @click="search" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          @click="exportHandle"
          type="warning"
          :disabled="disabledExport"
          icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column
          prop="supplier_title"
          align="center"
          label="供应商名称"
        >
        </el-table-column>
        <el-table-column align="center" label="售后单号">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.as_no) }}
          </template>
        </el-table-column>
        <el-table-column prop="operator_at" align="center" label="完成时间">
          <template slot-scope="scope">
            {{ $empty.time(scope.row.operator_at) }}
          </template>
        </el-table-column>
        <el-table-column prop="order_no" align="center" label="订单号">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.order_no) }}
          </template>
        </el-table-column>
        <el-table-column prop="order_no" align="center" label="城市仓">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.order.logistics.name) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="客户姓名">
          <template
            slot-scope="scope"
            v-if="scope.row.order && scope.row.order.member"
          >
            {{ $empty.empty(scope.row.order.member.fullname) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="客户电话">
          <template
            slot-scope="scope"
            v-if="scope.row.order && scope.row.order.member"
          >
            {{ $empty.empty(scope.row.order.member.mobile) }}
          </template>
        </el-table-column>
        <el-table-column prop="username" align="center" label="客服专员">
          <template slot-scope="scope" v-if="scope.row.operator">
            {{ $empty.empty(scope.row.operator.username) }}
          </template>
        </el-table-column>
        <el-table-column prop="after_type" align="center" label="售后类型">
          <template slot-scope="scope">
            <span v-if="scope.row.after_type == 1">用户售后</span>
            <span v-else-if="scope.row.after_type == 2">品控售后</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'afterDetail',
                query: {
                  id: scope.row.id,
                  state: scope.row.state,
                  word: 'detail',
                },
              }"
              class="main-color pointer text-none"
              >查看详情
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from1.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { BASE } from "@/api";
export default {
  name: "supplier",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        order_no: "",
        supplier_title: "",
        logistics_id: "",
        after_type: "",
        mobile: "",
        as_no: "",
        // as_at: "",
        page: 1,
        pageSize: 10,
        afterSalesFinishTimeStart: "",
        afterSalesFinishTimeEnd: "",
      },
      time: "",
      from1: {
        order_no: "",
        supplier_title: "",
        logistics_id: "",
        after_type: "",
        mobile: "",
        as_no: "",
        // as_at: "",
        page: 1,
        pageSize: 10,
        afterSalesFinishTimeStart: "",
        afterSalesFinishTimeEnd: "",
      },
      tableData: [],
      currentPage: 1,
      total: 0,
      options1: [],
      list: [],
      list1: [],
      cityStoreList: [], // 城市仓列表
      loadingCityStore: false,
      disabledExport: false,
    };
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      /** 历史丢参问题，修复 start */
      const url = this.tool.getUrl(window.location.href);
      // 无效的值
      const invalid = ["", null, undefined, "null", "undefined"];
      url.page = (invalid.includes(url.page) && 1) || url.page;
      url.pageSize = (invalid.includes(url.pageSize) && 10) || url.pageSize;
      /** 历史丢参问题，修复 end */
      this.from = url;
      this.from1 = url;
      this.currentPage = Number(this.from1.page);
      this.from1.pageSize = Number(this.from1.pageSize);
      console.log(this.from1);
    }
    this.citylist();
    this.hqlist();
  },
  methods: {
    startchanghe(val) {
      console.log(val, "122");
      if (val) {
        this.from1.afterSalesFinishTimeStart = val[0] ? val[0] : "";
        this.from1.afterSalesFinishTimeEnd = val[1] ? val[1] : "";
      } else {
        this.from1.afterSalesFinishTimeStart = "";
        this.from1.afterSalesFinishTimeEnd = "";
      }
    },
    // 获取城市仓列表
    async citylist() {
      this.loadingCityStore = true;
      try {
        const res = await this.$api.general.logisticsList({
          name: "",
          page: 1,
          pageSize: 1000,
        });
        console.log(res, "获取数据");
        this.cityStoreList = res.data.data;
        console.log(this.cityStoreList, "this.cityStoreList==");
      } catch (error) {
        console.log(error, "logisticsList");
      } finally {
        this.loadingCityStore = false;
      }
    },
    search() {
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    Refresh() {
      this.time = "";
      this.from = {
        order_no: "",
        supplier_title: "",
        after_type: "",
        mobile: "",
        as_no: "",
        // as_at: "",
        page: 1,
        pageSize: 10,
        afterSalesFinishTimeStart: "",
        afterSalesFinishTimeEnd: "",
      };
      this.from1 = {
        order_no: "",
        supplier_title: "",
        after_type: "",
        mobile: "",
        as_no: "",
        // as_at: "",
        page: 1,
        pageSize: 10,
        afterSalesFinishTimeStart: "",
        afterSalesFinishTimeEnd: "",
      };
      this.currentPage = 1;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    /**
     * 导出逻辑
     */
    async exportHandle() {
      this.disabledExport = true;
      const query = {
        ...this.from1,
        token: sessionStorage.getItem("token"),
      };
      delete query.page;
      delete query.pageSize;
      this.$api.general
        .aftersalefinishexport(query)
        .then((res) => {
          this.goExportCenter();
        })
        .catch((err) => {
          this.disabledExport = false;
        });
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    hqlist() {
      this.loading = true;
      this.$api.general.aftersaleEdList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.supplier {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    align-items: start;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .inputs {
        width: 207px;
        margin-right: 8px;
        margin-bottom: 5px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }
}

.colors {
  color: #fa884c;
}

.times {
  margin-right: 8px;
}

// .el-picker-panel {
//   left: 1245px !important;
// }
</style>
