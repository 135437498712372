var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "supplier bj" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c("el-button", {
          staticClass: "bjsearch",
          attrs: { type: "primary", icon: "el-icon-refresh" },
          on: { click: _vm.Refresh },
        }),
        _c(
          "div",
          { staticClass: "seach" },
          [
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入供应商", clearable: "" },
                  model: {
                    value: _vm.from1.supplier_title,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "supplier_title", $$v)
                    },
                    expression: "from1.supplier_title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      filterable: "",
                      clearable: "",
                      placeholder: "请选择城市仓",
                      loading: _vm.loadingCityStore,
                    },
                    model: {
                      value: _vm.from1.logistics_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "logistics_id", $$v)
                      },
                      expression: "from1.logistics_id",
                    },
                  },
                  _vm._l(_vm.cityStoreList, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id.toString() },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入订单号", clearable: "" },
                  model: {
                    value: _vm.from1.order_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "order_no", $$v)
                    },
                    expression: "from1.order_no",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "margin-right": "10px" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    "time-arrow-control": true,
                    type: "daterange",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                    "range-separator": "—",
                    "start-placeholder": "完成日期起",
                    "end-placeholder": "完成日期止",
                  },
                  on: { change: _vm.startchanghe },
                  model: {
                    value: _vm.time,
                    callback: function ($$v) {
                      _vm.time = $$v
                    },
                    expression: "time",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入客户电话", clearable: "" },
                  model: {
                    value: _vm.from1.mobile,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "mobile", $$v)
                    },
                    expression: "from1.mobile",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入售后单号", clearable: "" },
                  model: {
                    value: _vm.from1.as_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "as_no", $$v)
                    },
                    expression: "from1.as_no",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "请选择售后类型" },
                    model: {
                      value: _vm.from1.after_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "after_type", $$v)
                      },
                      expression: "from1.after_type",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "用户售后", value: "1" },
                    }),
                    _c("el-option", {
                      attrs: { label: "品控售后", value: "2" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.search },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  type: "warning",
                  disabled: _vm.disabledExport,
                  icon: "el-icon-upload2",
                },
                on: { click: _vm.exportHandle },
              },
              [_vm._v("导出")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "auto-table-flex" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: {
              data: _vm.tableData,
              height: "100%",
              border: true,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                width: "100px",
                align: "center",
                label: "序号",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "supplier_title",
                align: "center",
                label: "供应商名称",
              },
            }),
            _c("el-table-column", {
              attrs: { align: "center", label: "售后单号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$empty.empty(scope.row.as_no)) + " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "operator_at",
                align: "center",
                label: "完成时间",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$empty.time(scope.row.operator_at)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "order_no", align: "center", label: "订单号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$empty.empty(scope.row.order_no)) + " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "order_no", align: "center", label: "城市仓" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$empty.empty(scope.row.order.logistics.name)
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "客户姓名" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.order && scope.row.order.member
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.order.member.fullname
                                  )
                                ) +
                                " "
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "客户电话" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.order && scope.row.order.member
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.order.member.mobile
                                  )
                                ) +
                                " "
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("el-table-column", {
              attrs: { prop: "username", align: "center", label: "客服专员" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.operator
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$empty.empty(scope.row.operator.username)
                                ) +
                                " "
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("el-table-column", {
              attrs: { prop: "after_type", align: "center", label: "售后类型" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.after_type == 1
                        ? _c("span", [_vm._v("用户售后")])
                        : scope.row.after_type == 2
                        ? _c("span", [_vm._v("品控售后")])
                        : _c("span", [_vm._v("—")]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "main-color pointer text-none",
                          attrs: {
                            to: {
                              name: "afterDetail",
                              query: {
                                id: scope.row.id,
                                state: scope.row.state,
                                word: "detail",
                              },
                            },
                          },
                        },
                        [_vm._v("查看详情 ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.from1.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }